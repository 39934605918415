import "./App.css";
import { CssBaseline, CssVarsProvider, extendTheme } from "@mui/joy";
import { Outlet } from "react-router-dom";
import { useEffect, useLayoutEffect } from "react";
import useScript from "./hooks/useScript";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import "dayjs/locale/pt-br";
import ErrorPage from "./components/ErrorPage";
import { ErrorBoundary } from "@sentry/react";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga4";

const importModuleErrors = [
  "Failed to fetch dynamically imported module",
  "Failed to load module script",
];

const theme = extendTheme({
  colorSchemes: {
    light: {
      palette: {
        primary: {
          50: "#e9ebf0",
          100: "#c7ccdc",
          200: "#a4acc4",
          300: "#68729a",
          400: "#404876",
          500: "#2d2f50",
          600: "#2c2e4e",
          700: "#252641",
          800: "#1d1f34",
          900: "#161727",
        },
      },
    },
    dark: {
      palette: {
        primary: {
          50: "#e9ebf0",
          100: "#c7ccdc",
          200: "#a4acc4",
          300: "#828bab",
          400: "#68729a",
          500: "#4f5a8a",
          600: "#495281",
          700: "#404876",
          800: "#393f69",
          900: "#2d2f50",
        },
      },
    },
  },
});

const useEnhancedEffect =
  typeof window !== "undefined" ? useLayoutEffect : useEffect;

export default function App() {
  const status = useScript("https://unpkg.com/feather-icons");
  // useScript('https://player.pandavideo.com.br/api.v2.js');

  useEnhancedEffect(() => {
    // Feather icon setup: https://github.com/feathericons/feather#4-replace
    // @ts-ignore
    if (typeof feather !== "undefined") {
      // eslint-disable-next-line no-undef
      feather.replace();
    }
  }, [status]);

  ReactGA.initialize("G-LD19VNZ5F7");
  const location = useLocation();
  useEffect(() => {
    // ReactGA.send({ hitType: "pageview", page: location.pathname + location.search , title: location.pathname});
  }, [location]);

  const handleError = (error) => {
    for (const expected of importModuleErrors) {
      if (error.toString().includes(expected)) {
        window.location.reload();
        break;
      }
    }
  };

  return (
    <CssVarsProvider
      defaultMode="light"
      theme={theme}
      disableTransitionOnChange
    >
      <CssBaseline />
      <ErrorBoundary fallback={<ErrorPage />} onError={handleError}>
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="pt-br">
          <Outlet />
        </LocalizationProvider>
      </ErrorBoundary>
    </CssVarsProvider>
  );
}
