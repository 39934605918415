import { lazy, StrictMode, Suspense, useEffect } from "react";
import { createRoot } from "react-dom/client";
import "./index.css";
import App from "./App.jsx";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { StyledEngineProvider } from "@mui/joy";
import * as Sentry from "@sentry/react";
import Loading from "./components/Loading.jsx";
import ErrorPage from "./components/ErrorPage.jsx";
import dayjs from "dayjs";
import "./core/axios-config";
import "dayjs/locale/pt-br";
import { getEnv } from "../docker/env.js";
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from "react-router-dom";

dayjs.locale("pt-br");

if (typeof document !== "undefined") {
  if (window.innerWidth >= 1200) {
    // document.body.style.overflow = 'hidden';
    document.documentElement.style.setProperty("--SideNavigation-slideIn", "1");
  } else {
    document.documentElement.style.setProperty("--SideNavigation-margin", "0");
  }
}

//Auth
const Auth = lazy(() =>
  import("./pages/auth/Auth").catch((error) => console.log(error)),
);
const Login = lazy(() => import("./pages/auth/Login"));
const ForgetPassword = lazy(() => import("./pages/auth/ForgetPassword"));
const Signup = lazy(() => import("./pages/auth/Signup"));
const ForgetPasswordForm = lazy(
  () => import("./pages/auth/ForgetPasswordForm"),
);
const CodeSent = lazy(() => import("./pages/auth/CodeSent"));

//Admin
const Admin = lazy(() => import("./pages/admin/Admin"));
const Users = lazy(() => import("./pages/admin/users/Users"));
const Profile = lazy(() => import("./pages/admin/profile/ProfilePage"));
const Dashboard = lazy(() => import("./pages/admin/Dashboard"));
const SuggestionsPage = lazy(
  () => import("./pages/admin/suggestions/SuggestionsPage"),
);
const EssayListPage = lazy(
  () => import("./pages/admin/essay-view/EssayListPage"),
);
const QuestionListPage = lazy(
  () => import("./pages/admin/question-view/QuestionListPage"),
);
const ExamsPage = lazy(
  () => import("./pages/admin/registration/exam/ExamsPage"),
);
const ExamDetail = lazy(
  () => import("./pages/admin/registration/exam/ExamDetail"),
);
const QuestionTablePage = lazy(
  () => import("./pages/admin/registration/question/QuestionTablePage"),
);
const QuestionDetail = lazy(
  () => import("./pages/admin/registration/question/QuestionDetail"),
);
const CommentedAnswerDetail = lazy(
  () => import("./pages/admin/registration/question/CommentedAnswerDetail"),
);
const DomainPage = lazy(() => import("./components/domain/DomainPage"));
const CommentsPage = lazy(() => import("./pages/admin/comments/CommentsPage"));
const CourseListPage = lazy(
  () => import("./pages/admin/courses/CourseListPage"),
);
const MyCourses = lazy(() => import("./pages/admin/my-courses/MyCoursesPage"));
const CoursesByInstitutionPage = lazy(
  () => import("./pages/admin/institutions/CoursesByInstitutionPage"),
);
const LecturePage = lazy(() => import("./pages/admin/lectures/LecturePage"));
const CourseStatementPage = lazy(
  () => import("./pages/admin/course-statement/CourseStatementPage"),
);
const UserSubscriptionPage = lazy(
  () => import("./pages/admin/users/user-subscription/UserSubscriptionPage"),
);
const UserCoursePage = lazy(
  () => import("./pages/admin/users/user-course/UserCoursePage"),
);
const LectureRegistrationPage = lazy(
  () => import("./pages/admin/registration/lecture/LectureRegistrationPage"),
);
const LectureRegistrationDetail = lazy(
  () => import("./pages/admin/registration/lecture/LectureRegistrationDetail"),
);
const CourseRegistrationPage = lazy(
  () => import("./pages/admin/registration/course/CourseRegistrationPage"),
);
const CourseEditPage = lazy(
  () => import("./pages/admin/registration/course/CourseEditPage"),
);
const ModuleEditPage = lazy(
  () => import("./pages/admin/registration/course/module/ModuleEditPage"),
);
const InstitutionPage = lazy(
  () => import("./pages/admin/institution/InstitutionPage"),
);

const sentryCreateBrowserRouter =
  Sentry.wrapCreateBrowserRouterV7(createBrowserRouter);

const router = sentryCreateBrowserRouter([
  {
    path: "/",
    element: (
      <Suspense fallback={<Loading />}>
        <App />
      </Suspense>
    ),
    errorElement: <ErrorPage />,
    children: [
      {
        path: "",
        element: (
          <Suspense fallback={<Loading />}>
            {" "}
            <Admin />
          </Suspense>
        ),
        children: [
          {
            path: "",
            element: (
              <Suspense fallback={<Loading />}>
                {" "}
                <Dashboard />
              </Suspense>
            ),
          },
          {
            path: "courses-institution",
            element: (
              <Suspense fallback={<Loading />}>
                {" "}
                <CoursesByInstitutionPage />{" "}
              </Suspense>
            ),
          },
          {
            path: "course",
            element: (
              <Suspense fallback={<Loading />}>
                {" "}
                <CourseListPage />
              </Suspense>
            ),
          },
          {
            path: "course/:courseId",
            element: (
              <Suspense fallback={<Loading />}>
                {" "}
                <CourseStatementPage />
              </Suspense>
            ),
          },
          {
            path: "course/:courseId/lecture",
            element: (
              <Suspense fallback={<Loading />}>
                {" "}
                <LecturePage />
              </Suspense>
            ),
          },
          {
            path: "my-courses",
            element: (
              <Suspense fallback={<Loading />}>
                {" "}
                <MyCourses />
              </Suspense>
            ),
          },
          {
            path: "institution/:institutionId",
            element: (
              <Suspense fallback={<Loading />}>
                {" "}
                <InstitutionPage />
              </Suspense>
            ),
          },
          {
            path: "question-view",
            element: (
              <Suspense fallback={<Loading />}>
                {" "}
                <QuestionListPage />
              </Suspense>
            ),
          },
          {
            path: "essay-view",
            element: (
              <Suspense fallback={<Loading />}>
                {" "}
                <EssayListPage />
              </Suspense>
            ),
          },
          {
            path: "users",
            element: (
              <Suspense fallback={<Loading />}>
                {" "}
                <Users />
              </Suspense>
            ),
          },
          {
            path: "users/:userId/subscription",
            element: (
              <Suspense fallback={<Loading />}>
                <UserSubscriptionPage />
              </Suspense>
            ),
          },
          {
            path: "users/:userId/course",
            element: (
              <Suspense fallback={<Loading />}>
                <UserCoursePage />
              </Suspense>
            ),
          },
          {
            path: "profile",
            element: (
              <Suspense fallback={<Loading />}>
                {" "}
                <Profile />
              </Suspense>
            ),
          },
          {
            path: "settings/:domain",
            element: (
              <Suspense fallback={<Loading />}>
                {" "}
                <DomainPage />
              </Suspense>
            ),
          },
          {
            path: "registration",
            children: [
              {
                path: "question",
                element: (
                  <Suspense fallback={<Loading />}>
                    {" "}
                    <QuestionTablePage />
                  </Suspense>
                ),
              },
              {
                path: "question/:id",
                element: (
                  <Suspense fallback={<Loading />}>
                    {" "}
                    <QuestionDetail />
                  </Suspense>
                ),
              },
              {
                path: "question/:id/commented-answer",
                element: (
                  <Suspense fallback={<Loading />}>
                    {" "}
                    <CommentedAnswerDetail />
                  </Suspense>
                ),
              },
              {
                path: "exam",
                element: (
                  <Suspense fallback={<Loading />}>
                    {" "}
                    <ExamsPage />
                  </Suspense>
                ),
              },
              {
                path: "exam/:id",
                element: (
                  <Suspense fallback={<Loading />}>
                    {" "}
                    <ExamDetail />
                  </Suspense>
                ),
              },
              {
                path: "lecture",
                element: (
                  <Suspense fallback={<Loading />}>
                    {" "}
                    <LectureRegistrationPage />
                  </Suspense>
                ),
              },
              {
                path: "lecture/:id",
                element: (
                  <Suspense fallback={<Loading />}>
                    {" "}
                    <LectureRegistrationDetail />
                  </Suspense>
                ),
              },
              {
                path: "course/",
                element: (
                  <Suspense fallback={<Loading />}>
                    {" "}
                    <CourseRegistrationPage />
                  </Suspense>
                ),
              },
              {
                path: "course/edit/:id",
                element: (
                  <Suspense fallback={<Loading />}>
                    {" "}
                    <CourseEditPage />
                  </Suspense>
                ),
              },
              {
                path: "course/edit/:courseId/module/:moduleId",
                element: (
                  <Suspense fallback={<Loading />}>
                    {" "}
                    <ModuleEditPage />
                  </Suspense>
                ),
              },
            ],
          },
          {
            path: "suggestion",
            element: (
              <Suspense fallback={<Loading />}>
                {" "}
                <SuggestionsPage />
              </Suspense>
            ),
          },
          {
            path: "comment",
            element: (
              <Suspense fallback={<Loading />}>
                {" "}
                <CommentsPage />
              </Suspense>
            ),
          },
        ],
      },
      {
        path: "",
        element: (
          <Suspense fallback={<div>Carregando...</div>}>
            {" "}
            <Auth />
          </Suspense>
        ),
        children: [
          {
            path: "login",
            element: (
              <Suspense fallback={<div>Carregando...</div>}>
                {" "}
                <Login />
              </Suspense>
            ),
          },
          {
            path: "signup",
            element: (
              <Suspense fallback={<div>Carregando...</div>}>
                {" "}
                <Signup />
              </Suspense>
            ),
          },
          {
            path: "forget-password",
            element: (
              <Suspense fallback={<div>Carregando...</div>}>
                {" "}
                <ForgetPassword />
              </Suspense>
            ),
          },
          {
            path: "forget-password/:code",
            element: (
              <Suspense fallback={<div>Carregando...</div>}>
                {" "}
                <ForgetPasswordForm />
              </Suspense>
            ),
          },
          {
            path: "code-sent",
            element: (
              <Suspense fallback={<div>Carregando...</div>}>
                {" "}
                <CodeSent />
              </Suspense>
            ),
          },
        ],
      },
    ],
  },
]);

const env = getEnv();

Sentry.init({
  environment: env.VITE_ENVIRONMENT,
  dsn: "https://c9c3237ed9acc075ba6d46e0cc6be9f6@o4506396229107712.ingest.us.sentry.io/4506507404247040",
  enabled: import.meta.env.VITE_ENABLE_SENTRY === "true",
  release: import.meta.env.VITE_VERSION,
  integrations: [
    Sentry.browserTracingIntegration({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: [
        /^https:\/\/plataforma\.cursosdoportal\.com\.br\/api/,
      ],
    }),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
    Sentry.reactRouterV7BrowserTracingIntegration({
      useEffect: useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
  ],
  // Performance Monitoring
  tracesSampleRate: 1, //  Capture 100% of the transactions
  // Session Replay
  replaysSessionSampleRate: 0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

createRoot(document.getElementById("root")).render(
  <StrictMode>
    <StyledEngineProvider injectFirst>
      <RouterProvider router={router} />
    </StyledEngineProvider>
  </StrictMode>,
);
